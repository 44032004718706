import { Component, OnInit, Input } from '@angular/core';
import { TabsModel } from '../../molecules/m-tabs/m-tabs.model';

@Component({
    selector: 'o-logo-slider',
    templateUrl: './o-logo-slider.component.html',
    styleUrls: ['./o-logo-slider.component.scss'],
})
export class LogoSliderComponent implements OnInit {
    @Input() hasLogos: boolean = false;
    @Input() hasLogos2: boolean = false;
    @Input() hasLogos3: boolean = false;
    @Input() hasHeader: boolean = false;
    @Input() tabs?: TabsModel[];
    @Input() greyBackground: boolean = false;
    @Input() greyImages: boolean = false;
    @Input() containerClass?: string;
    @Input() parallaxValue: number = -100;
    @Input() parallaxSpeed: number = 0.2;

    constructor() {}

    ngOnInit(): void {}
}
