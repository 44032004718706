<div
    class="m-cta-banner"
    [ngClass]="[containerClass ? containerClass : '']">
    <div [ngClass]="{ 'secondary-background': secondaryBgColor, background: true }">
        <div
            data-aos="fade-up"
            data-aos-delay="10"
            data-aos-once="true"
            class="container d-flex justify-content-between align-items-center">
            <div class="row w-100 align-items-center">
                <div [ngClass]="{ 'col-md-12 col-lg-8': true, 'd-flex align-items-center': iconName }">
                    <a-icon
                        class="hide-mobile"
                        *ngIf="iconName"
                        [white]="true"
                        [iconSet]="iconSet"
                        [source]="iconName"
                        data-aos="fade-up"
                        data-aos-delay="10"
                        data-aos-once="true"></a-icon>
                    <div [ngClass]="{ 'ml-5': iconName }">
                        <div *ngIf="title">
                            <p
                                class="text-white fw-bold mb-lg-0 h3"
                                [innerHtml]="title | translate"
                                data-aos="fade-up"
                                data-aos-delay="10"
                                data-aos-once="true"></p>
                            <ng-content select="title"></ng-content>
                        </div>
                        <p
                            class="text-white mb-lg-0"
                            *ngIf="desc"
                            [innerHtml]="desc | translate"
                            data-aos="fade-up"
                            data-aos-delay="10"
                            data-aos-once="true"></p>
                    </div>
                    <a
                        *ngIf="btnLabel && showBtnAsLink"
                        [routerLink]="btnLink"
                        class="link"
                        data-aos="fade-up"
                        data-aos-delay="10"
                        data-aos-once="true">
                        {{ btnLabel | translate | uppercase }}
                    </a>
                </div>
                <div class="col-md-12 col-lg-4 btn-wrapper d-flex justify-content-center">
                    <a-button
                        *ngIf="btnLabel && !showBtnAsLink"
                        [buttonStyle]="btnClassName ? btnClassName : ''"
                        type="button"
                        (onClick)="click()"
                        data-aos="fade-up"
                        data-aos-delay="10"
                        data-aos-once="true">
                        <span [innerHtml]="getBtnLabel"></span>
                    </a-button>
                </div>
            </div>
            <div
                class="w-50"
                *ngIf="imageName">
                <a-image
                    [imageConfig]="imageName"
                    [alt]="(title ? title : '') | translate"
                    data-aos="fade-up"
                    data-aos-delay="10"
                    data-aos-once="true"></a-image>
            </div>
        </div>
    </div>
</div>
