<div
    class="m-card-item"
    [class.cursor-pointer]="isClickable"
    [class.dark-mode]="darkMode"
    [ngClass]="[containerClass ? containerClass : '']"
    (click)="click()">
    <div
        data-aos="fade-up"
        data-aos-delay="10"
        data-aos-once="true"
        *ngIf="headerTitle"
        class="header">
        <h1 class="title">{{ headerTitle | translate }}</h1>
    </div>
    <div
        data-aos="fade-up"
        data-aos-delay="10"
        data-aos-once="true"
        [ngClass]="{
            border: isCard,
            credential: isCredential,
            'no-side-borders': noSideBorders,
            'border-bottom': borderBottom
        }">
        <div
            [ngClass]="{
                'padding-0': headerTitle || padding0,
                'position-relative align-self-center': true,
                'w-50': w50,
                'w-100': w100,
                'icon-left': iconLeft
            }"
            *ngIf="imgSrc || backgroundImgSrc">
            <a-image
                *ngIf="imgSrc"
                [greyScale]="greyScaleImage"
                [imageConfig]="imgSrc"
                [alt]="(title ? title : '') | translate"
                [mediumImage]="mediumSizeImg"
                [ngClass]="{ 'full-image': isCard, image: !isCard, 'cursor-pointer': hasModal }"></a-image>
            <div
                *ngIf="backgroundImgSrc"
                class="backgroundImage">
                <a-image
                    [cardHeight]="true"
                    [greyScale]="greyScaleImage"
                    [bgImageConfig]="backgroundImgSrc"></a-image>
            </div>
            <div
                *ngIf="iconName && callback"
                class="image-icon"
                (click)="callback()">
                <a-icon [source]="iconName"></a-icon>
            </div>
        </div>

        <div
            [ngClass]="{
                'image-body': imgSrc || backgroundImgSrc,
                'padding-0': headerTitle || padding0,
                body: true,
                'p-0': noPadding,
                'icon-left': iconLeft
            }">
            <div
                *ngIf="iconName && !imgSrc && !backgroundImgSrc"
                [ngClass]="{
                    'mb-3': !iconLeft,
                    'mr-5': iconLeft
                }">
                <a-icon
                    [iconSet]="iconSet"
                    [source]="iconName"></a-icon>
            </div>
            <a-paragraph
                *ngIf="halfTitle"
                [contrast]="false"
                [medium]="true"
                [content]="halfTitle | translate | uppercase"></a-paragraph>
            <ng-content select="[title]"></ng-content>
            <ng-container *ngIf="title">
                <a-title
                    *ngIf="!regularSize"
                    [noBottom]="iconLeft || !desc"
                    [title4]="title | translate"
                    [truncate]="false"
                    [contrast]="darkMode"></a-title>
                <a-title
                    *ngIf="regularSize"
                    [contrast]="darkMode"
                    [title5]="title | translate"></a-title>
            </ng-container>
            <hr
                *ngIf="hasLines"
                class="hr-bold"
                data-aos="fade-up"
                data-aos-delay="10" />
            <p
                [ngClass]="{
                    'mb-0': headerTitle
                }"
                *ngIf="desc">
                <span [innerHtml]="desc | translate"></span>
            </p>
            <a
                *ngIf="btnTitle"
                href="#"
                class="link">
                {{ btnTitle | translate | uppercase }}
            </a>
            <hr
                *ngIf="hasLines"
                data-aos="fade-up"
                data-aos-delay="10"
                data-aos-once="true" />
            <div
                class="link"
                *ngIf="hasLink && linkLabel">
                <a-link
                    [isExternal]="true"
                    [url]="linkUrl ? linkUrl : ''"
                    target="_blank"
                    componentClass="link link-color">
                    <span [innerHtml]="linkLabel | translate | uppercase"></span>
                </a-link>
            </div>
            <div
                class="link"
                *ngIf="!hasLink && callback && linkLabel">
                <a-link
                    (click)="callback()"
                    componentClass="link-color">
                    <span [innerHtml]="linkLabel | translate | uppercase"></span>
                </a-link>
            </div>
        </div>
        <ng-content select="[avatar]"></ng-content>
    </div>

    <m-modal
        *ngIf="hasModal"
        [id]="modalId">
        <ng-content></ng-content>
    </m-modal>
</div>
