import { Routes, Route } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ShellComponent } from './shell.component';
import { Injectable } from '@angular/core';

/**
 * Provides helper methods to create routes.
 */
@Injectable({
    providedIn: 'root',
})
export class Shell {
    constructor(private http: HttpClient) {}
    /**
     * Creates routes using the shell component and authentication.
     * @param routes The routes to add.
     * @return The new route using shell as the base.
     */
    static childRoutes(routes: Routes): Route {
        return {
            path: '',
            component: ShellComponent,
            children: routes,
        };
    }

    getNavbarMenu(): any[] {
        return [
            {
                label: 'Work',
                url: '/work',
            },
            {
                label: 'Industries',
                url: '/industries',
            },
            {
                label: 'About',
                url: '/about',
            },
            {
                label: 'Capabilities',
                url: '/services',
            },
            // {
            //     label: 'Initiatives',
            //     url: '/initiatives',
            //     subMenuItems: [
            //         { label: 'investments', url: '/initiatives/investments' },
            //         { label: 'open-source', url: '/initiatives/open-source' },
            //         { label: 'human rights', url: '/initiatives/human-rights' },
            //     ],
            // },
            {
                label: 'Company',
                megaMenu: true,
                subMenuItems: [
                    {
                        label: 'Initiatives',
                        icon: '/assets/icons/initiatives-icon.svg',
                        desc: 'Turning passion into purpose',
                        url: '/initiatives',
                    },
                    {
                        label: 'Events',
                        icon: '/assets/icons/calendar-icon.svg',
                        desc: 'Make life a little more eventful',
                        url: '/events',
                    },
                    {
                        label: 'Team',
                        icon: '/assets/icons/team-icon.svg',
                        desc: 'A passionate team of creatives, engineers, and product strategists',
                        url: '/team',
                    },
                    {
                        label: 'Testimonials',
                        icon: '/assets/icons/testimonials-icon.svg',
                        desc: 'Behind every great product, is an even greater client',
                        url: '/testimonials',
                    },
                    {
                        label: 'Careers',
                        icon: '/assets/icons/careers-icon.svg',
                        desc: "We're always looking for outstanding talent",
                        url: '/careers',
                    },
                    { label: 'Blog', url: '/blog', hideDesktop: true },
                ],
            },
            {
                label: 'Contact',
                url: '/contact',
                hideMobile: true,
                isBtn: true,
                hideAuth: true,
            },
        ];
    }
}
