export interface ICdnElements {
    elType: string;
    src: string;
    active: boolean;
    isAsync?: boolean;
    isDefer?: boolean;
    isCrossorigin?: boolean;
    type?: string;
    rel?: string;
}

export class AppSettings {
    public static PRERENDERING_ROUTES = [
        '/',
        '/contact',
        '/work',
        '/events',
        '/blog',
        '/team',
        '/careers',
        '/testimonials',
        '/industries'
    ];
    public static STATIC_ROUTES = [
        '/',
        '/contact',
        '/work',
        '/events',
        '/blog',
        '/team',
        '/careers',
        '/testimonials',
        '/industries',
        '/services',
        '/initiatives',
        '/initiatives/investments',
        '/initiatives/open-source',
        '/initiatives/human-rights',
    ];

    public static CDN_ELEMENTS = [
        {
            elType: 'link', // Vime: The library is lazy loaded so you have nothing to worry about, only what you use will be loaded.
            src: 'https://cdn.jsdelivr.net/npm/@vime/core@^5/themes/default.css',
            rel: 'stylesheet',
            active: false,
        },
        {
            elType: 'link',
            src: 'https://cdn.lineicons.com/3.0/lineicons.css',
            rel: 'stylesheet',
            active: false,
        },
        {
            elType: 'script',
            src: 'https://cdn.jsdelivr.net/npm/@vime/core@^5/dist/vime/vime.esm.js',
            type: 'module',
            isDefer: true,
            active: false,
        },
        {
            elType: 'script',
            src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDr7Q3TPIRgABAktvsFG7eDTgG6sqSW178',
            isAsync: true,
            active: false,
        },
        {
            elType: 'link',
            src: 'https://fonts.googleapis.com',
            rel: 'preconnect',
            active: false,
        },
        {
            elType: 'link',
            src: 'https://fonts.gstatic.com',
            rel: 'preconnect',
            isCrossorigin: true,
            active: false,
        },
    ];

    public static SUPPORTED_LANGUAGES = ['en-US'];
    public static I18NPAGES = ['resources', 'login'];
    public static DEFAULT_LANGUAGE = 'en-US';
    public static GOOGLE_API_KEY = 'AIzaSyDr7Q3TPIRgABAktvsFG7eDTgG6sqSW178';
    public static RECAPTCHA_SITE_KEY = '';
    public static SITE = 'scalio';
    public static SEO_SETTING = '/assets/data/seo.json';
    public static MAX_CACHE_AGE = 60000;
    public static BLOG_PAGE_SIZE = 12;
    public static PAGE_SIZE = 5;
    public static ISR_INVALIDATE_SECRET_TOKEN = 'E94777D897333B53E295571EB9F3E';
}
