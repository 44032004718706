<div
    class="m-no-data-content"
    [ngClass]="[containerClass ? containerClass : '']">
    <div class="icon-wrapper">
        <a-icon
            [iconSet]="true"
            source="fas fa-exclamation-circle"></a-icon>
        <p [innerHtml]="content | translate"></p>
    </div>
</div>
