import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NavigationModel, SubMenuNavigationModel } from './m-navbar-item.model';
import { AuthenticationService } from '../../../cms/auth/authentication.service';
import { PagesService } from '@src/app/services/pages.service';
import { CMSList } from '@src/app/@shared/cms/models/cms-list.model';
import { Blog } from '@src/app/@shared/cms/models/blog.model';

@Component({
    selector: 'm-navigation',
    templateUrl: './m-navbar-item.component.html',
    styleUrls: ['./m-navbar-item.component.scss'],
})
export class NavbarItemComponent implements OnInit, OnChanges {
    @Input() navlinks!: Array<NavigationModel>;
    @Input() footerLinks!: Array<NavigationModel>;
    links!: Array<NavigationModel>;
    @Input() hasBorder!: boolean;
    @Input() hideMobile!: boolean;
    @Input() hideMenu: boolean = false;
    @Input() containerClass?: string;
    @Output() closeModal: EventEmitter<any> = new EventEmitter();
    featuredBlog!: Blog;

    constructor(
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private pagesService: PagesService,
    ) {}

    ngOnInit(): void {
        const args = { sort: 'position', page: 1, limit: 1 };

        this.pagesService.getBlogs(args).subscribe((posts: any) => {
            const { data } = posts as CMSList<Blog>;
            if (data.length > 0) {
                this.featuredBlog = data[0];
            }
            console.log(this.featuredBlog);
        });
    }

    ngOnChanges(): void {
        if (this.navlinks) this.links = this.navlinks.filter((item) => !item.isBtn);
    }

    getbtnLabel(key: string): string {
        return this.translateService.instant(key);
    }

    click(item: NavigationModel, submenu?: SubMenuNavigationModel) {
        item.isOpen = false;
        if (item.logOut || (submenu && submenu.logOut)) {
            this.authenticationService.logout();
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.navigate(['/'], { replaceUrl: true });
        }
        this.closeModal.emit();
    }
}
