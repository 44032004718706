import { CMSList } from '@app/@shared/cms/models/cms-list.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { mapLegalDocToFooterLinks } from '@app/mappers/legaldoc.mapper';
import { PagesService } from '../services/pages.service';
import { LegalDoc } from './../@shared/cms/models/legaldoc.model';
import { LoadingStateService } from '../services/loading-state.service';
import { Shell } from './shell.service';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@src/environments/environment';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { MessageService } from '../@shared/utils/message/message.service';
import { MessageServiceModel } from '../@shared/utils/message/message.model';
import { PlatformService } from '../@shared/utils/platform/platform.service';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
    navbarMenu!: any[];
    footerItems!: any[];
    hideInRoutes!: string[];
    footerCopyItemsLeft: Array<any> = [];
    footerCopyItemsRight: Array<any> = [];
    showFooter: boolean = true;
    subscription: Subscription | undefined;
    date = new Date().getFullYear();
    logoUrl: string = `${environment.baseUrl}/assets/icons/logo.svg`;
    showPage: boolean = false;

    constructor(
        private pagesService: PagesService,
        public loadingStateService: LoadingStateService,
        private shellServide: Shell,
        private router: Router,
        private state: TransferState,
        public messageService: MessageService,
        private platform: PlatformService,
    ) {}

    ngOnInit() {
        if (this.platform.isBrowser) {
            this.messageService.getMessage().subscribe((msj: MessageServiceModel) => {
                if (msj.type === 'loading') {
                    if (!msj.payload.show) {
                        document.body.style.backgroundColor = 'white';
                        this.showPage = true;
                    }
                }
            });
        }
        this.hideInRoutes = [];
        this.navbarMenu = this.shellServide.getNavbarMenu();
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this.findRoutesExclude(val.url);
            }
        });
        this.findRoutesExclude(this.router.url);
        this.loadFooterItems();
        this.footerCopyItemsLeft = [
            {
                iconSrc: '/assets/icons/social/facebook_icon.svg',
                href: '//www.facebook.com/ScalioLabs',
                target: '_blank',
                alt: 'facebook',
            },
            {
                iconSrc: '/assets/icons/social/linkedin_icon.svg',
                href: '//www.linkedin.com/company/scalio',
                target: '_blank',
                alt: 'linkedin',
            },
            {
                iconSrc: '/assets/icons/social/twitter_icon.svg',
                href: '//twitter.com/ScalioLabs',
                target: '_blank',
                alt: 'twitter',
            },
            {
                iconSrc: '/assets/icons/social/instagram_icon.svg',
                href: '//www.instagram.com/scal.io/',
                target: '_blank',
                alt: 'instagram',
            },
            {
                iconSrc: '/assets/icons/social/angellist_icon.svg',
                href: '//angel.co/scalio',
                target: '_blank',
                alt: 'angel',
            },
            {
                iconSrc: '/assets/icons/social/github_icon.svg',
                href: '//github.com/scalio',
                target: '_blank',
                alt: 'github',
            },
            {
                iconSrc: '/assets/icons/social/play_google_icon.svg',
                href: '//play.google.com/store/apps/dev?id=9177026320222391268',
                target: '_blank',
                alt: 'play.google',
            },
            {
                iconSrc: '/assets/icons/social/dribble_icon.svg',
                href: '//dribbble.com/scalio',
                target: '_blank',
                alt: 'dribbble',
            },
            {
                iconSrc: '/assets/icons/social/behance_icon.svg',
                href: '//www.behance.net/scaliosf',
                target: '_blank',
                alt: 'behance',
            },
            {
                iconSrc: '/assets/icons/social/glassdoor_icon.svg',
                href: '//www.glassdoor.com/Overview/Working-at-Scalio-EI_IE1404313.11,17.htm',
                target: '_blank',
                alt: 'glassdoor',
            },
        ];
        this.footerCopyItemsRight = [
            {
                label:
                    '© 2011 - ' +
                    this.date +
                    ' Scalio - <a class="text-decoration-none email-contrast" title="hello@scal.io" href="mailto:hello@scal.io?Subject=Hello%20Scalio!">hello@scal.io</a>',
            },
        ];

        const RESULT_KEY = makeStateKey<any>('shell-http-call');
        if (this.state.hasKey(RESULT_KEY)) {
            const policyDetail = this.state.get(RESULT_KEY, {});
            this.loadData(policyDetail);
        } else {
            this.subscription = this.pagesService
                .getPolicies({ sort: 'timeCreated', limit: 3, page: 1 })
                .subscribe((policyDetail) => {
                    if (this.platform.isServer) {
                        this.state.set(RESULT_KEY, policyDetail);
                    }
                    this.loadData(policyDetail);
                });
        }
    }

    loadData(policyDetail: CMSList<LegalDoc>) {
        const policies = policyDetail as CMSList<LegalDoc>;
        this.footerCopyItemsLeft = [...this.footerCopyItemsLeft];
        this.footerCopyItemsRight = [
            ...this.footerCopyItemsRight,
            ...mapLegalDocToFooterLinks(policies.data, 'policy'),
        ];
    }

    findRoutesExclude(url: string) {
        const idx = this.hideInRoutes.findIndex((x) => url.includes(x));
        this.showFooter = idx === -1;
    }

    loadFooterItems() {
        this.footerItems = [
            {
                containerClass: 'main-links',
                items: [
                    {
                        label: 'Home',
                        url: '/home',
                    },
                    {
                        label: 'About us',
                        url: '/about',
                    },
                    {
                        label: 'Blog',
                        url: '/blog',
                    },
                    {
                        label: 'Contact',
                        url: '/contact',
                    },
                    {
                        label: "<img loading='lazy' src='/assets/img/scalio-iso.png' alt='Scalio Logo' height='75' width='80'/>",
                    },
                ],
            },
            {
                containerClass: 'offices-footer',
                category: 'Offices',
                items: [
                    {
                        label: "<p class='fw-bolder mb-1'>San Francisco (HQ)</p><p class='text-white-50 mb-2'>548 Market Street</p><p class='text-white-50 mb-2'>San Francisco, CA 94104</p><a class='text-decoration-none' href='tel:(415) 294-1062'><p class='text-white-50 mb-2'>(415) 294-1062</p></a>",
                    },
                    {
                        label: "<p class='fw-bolder mb-1'>Orange County</p><p class='text-white-50 mb-2'>183 S Glassel St, Suite 1</p><p class='text-white-50 mb-2'>Orange, CA 92866</p><a class='text-decoration-none' href='tel:(714) 367-6620'><p class='text-white-50 mb-2'>(714) 367-6620</p></a>",
                    },
                    {
                        label: "<p class='fw-bolder mb-1'>Atlanta</p><p class='text-white-50 mb-2'>151 Ted Turner Dr NW, Suite 10</p><p class='text-white-50 mb-2'>Atlanta, GA 30303</p>",
                    },
                    {
                        label: "<p class='fw-bolder mb-1'>Sydney</p><p class='text-white-50 mb-2'>Scalio Australia Pty Ltd</p><p class='text-white-50 mb-2'>Sydney NSW 2000, Australia</p>",
                    },
                ],
            },
            {
                containerClass: 'company-footer',
                category: 'Company',
                items: [
                    {
                        label: 'Blog',
                        url: '/blog',
                    },
                    {
                        label: 'Team',
                        url: '/teams',
                    },
                    {
                        label: 'Careers',
                        url: '/',
                    },
                    {
                        label: 'Events',
                        url: '/events',
                    },
                    {
                        label: 'Testimonials',
                        url: '/testimonials',
                    },
                ],
            },
        ];
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
